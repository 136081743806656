import React,{useState} from "react";
import "./enquirenow.scss";
import { rightWhiteArrow } from "../../source";
import Joi from "joi";
import config from "../../config/config";
import http from "../../helpers/http";
import { useNavigate } from "react-router-dom";
const EnquireNow = () => {

  const [values, setValues] = useState({
    
    name: "",
   
    email: "",
    
    phone: "",
    message: "",
    
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  // const [consent, setConsent] = useState(false);



  let base_url = config.api_url + "/forms/enquiry";

  const navigate = useNavigate();

  const schema = Joi.object({
    name: Joi.string().trim().required().label("Name"),
   
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.number().required().label("Contact No"),
   

    // message: Joi.string().trim().label("Message"),
   
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);

    console.log({ errors });
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    // console.log(...formData);
    // return;

    const { data } = await http.post(base_url, values);

    if (data) {
      setValues({
        name: "",
   
        email: "",
        
        phone: "",
        message: "",
      });
      navigate("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type == "checkbox") {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.checked,
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };




  return (
    <>
      <section className="enquire_sec1">
        <div className="my_container">
          <div className="enquire_wrapper">
            <h2 className="heading">Enquire Now</h2>
          </div>
          <form className="form_wrapper" onSubmit={handleSubmit}>
            <div className="row form_row">
              <div className="col-lg-4 form_col">
                <div class="form-floating">
                  <input
                    type="text"
                    className="form-control input_field"
                    id="name"
                    placeholder="Name*"
                    name="name"
                      value={values.name}
                      onChange={handleChange}
                  />
                  <label for="name">Name*</label>
                  <p className="error">{formError.name}</p>
                </div>
              </div>
              <div className="col-lg-4 form_col">
                <div class="form-floating">
                  <input
                    type="email"
                    className="form-control input_field"
                    id="email"
                    placeholder="Email*"
                    name="email"
                      value={values.email}
                      onChange={handleChange}
                  />
                  <label for="email">Email Id*</label>
                  <p className="error">{formError.email}</p>
                </div>
              </div>
              <div className="col-lg-4 form_col">
                <div class="form-floating">
                  <input
                    type="tel"
                    className="form-control input_field"
                    id="tel"
                    placeholder="Contact No.*"
                    name="phone"
                      value={values.phone}
                      onChange={handleChange}
                  />
                  <label for="tel">Contact No.*</label>
                  <p className="error">{formError.phone}</p>
                </div>
              </div>
              <div className="col-lg-10 form_col">
                <div class="form-floating">
                  <input
                    type="text"
                    className="form-control input_field"
                    id="text"
                    placeholder="Message"
                    name="message"
                      value={values.message}
                      onChange={handleChange}
                  />
                  <label for="text">Message</label>
                  <p className="error">{formError.message}</p>
                </div>
              </div>
              <div className="col-lg-2 form_col">
                <div class="btn_wrapper">
                  <button type="submit" className="submit_btn">
                    {loading ? "Loading..":"Submit"}
                  </button>
                  <img
                    src={rightWhiteArrow}
                    alt="right arrow"
                    className="btn_img"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default EnquireNow;
