import React, { useState } from "react";
import "./home.scss";
import {
  Drdo,
  accordOpen,
  biotech,
  coating,
  coatingBackground,
  coatingMain,
  counterClient,
  counterYear,
  electrodes,
  greenLeft,
  mobileLeftArrow,
  mobileRightArrow,
  msme,
  nextGen,
  science,
  seal,
  silicon,
  whiteCircleLeft,
  whiteCircleRight,
} from "../../source";
import KnowMore from "../../components/KnowMore/KnowMore";
import { homeURL } from "../../helpers/paths";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Counter from "../../components/Counter/Counter";
import {
  aboutSliderData,
  bannerSliderData,
  mbCoatingData,
  ourProductData,
} from "../../data/homepageData";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import EnquireNow from "../../components/EnquireNow/EnquireNow";
import { useWindowSize } from "react-use";
import FooterV2 from "../../components/FooterV2/FooterV2";
import HeaderV2 from "../../components/HeaderV2/HeaderV2";
import { useEffect } from "react";

const Home = () => {
  const { width } = useWindowSize();
  const [isClicked, setIsClicked] = useState(0);

  const bannerList = bannerSliderData.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="banner_img_wrapper">
        <img
          src={width > 834 ? item.img : item.mbimg}
          alt={item.alt}
          className="banner_image"
        />
      </div>
      <div className="my_container">
        <div className="banner_content_wrapper">
          <h1
            className="banner_heading"
            dangerouslySetInnerHTML={{ __html: item.heading }}
          ></h1>
          <div className="cta_wrapper">
            <KnowMore link={item.link} text="Know More" />
          </div>
        </div>
      </div>
    </SwiperSlide>
  ));

  const aboutSliderList = aboutSliderData.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="about_img_wrapper">
        <img src={item.img} alt={item.alt} className="about_slider_img" />
      </div>
    </SwiperSlide>
  ));

  const productList = ourProductData.map((item, i) => (
    <Accordion.Item eventKey={i + ""} key={i}>
      <Accordion.Header onClick={() => setIsClicked(i)}>
        <div className="accor_heading_wrapper">
          <h3 className="heading">
            <span className="number">{item.numb}</span> {item.title}
          </h3>
          {/* <img src={isClicked === i ? accordOpen : accordClose} alt="arrow" /> */}
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="accor_desc_wrapper">
          <span
            className="desc"
            dangerouslySetInnerHTML={{ __html: item.details }}
          ></span>{" "}
          {item.linkType === "external" ? (
            <a
              href={item.link}
              // target="_blank"
              className="product_link"
            >
              Read More
            </a>
          ) : (
            <Link to={item.link} className="product_link">
              Read More
            </Link>
          )}
          {width <= 834 ? (
            <div className="mb_product_img_wrapper">
              <img
                src={item.img}
                alt={item.alt}
                className="product_img"
                loading="lazy"
              />
            </div>
          ) : null}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  ));

  const coatingList = mbCoatingData.map((item, i) => (
    <SwiperSlide key={i}>
      <div
        className="coating_box"
        style={{ backgroundColor: item.backgroundColor }}
      >
        <img src={item.img} alt={item.alt} className="coating_img" />
        <h3
          className="coating_title"
          style={{ color: item.textType === "white" ? "#ffffff" : "" }}
        >
          {item.title}
        </h3>
        <p
          className="coating_desc"
          style={{ color: item.textType === "white" ? "#ffffff" : "" }}
        >
          {item.desc}
        </p>
      </div>
    </SwiperSlide>
  ));

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <>
      <HeaderV2 />
      <section className="home_sec1">
        <div className="home_slider_wrapper">
          <Swiper
            spaceBetween={0}
            loop={true}
            // autoHeight={true}
            modules={[Navigation, Autoplay]}
            navigation={{
              prevEl: ".home_prev",
              nextEl: ".home_next",
            }}
            // autoplay={{
            //   delay: 4000,
            //   disableOnInteraction: false,
            // }}
            // breakpoints={{
            //   0: {
            //     autoHeight: false,
            //   },
            //   768: {
            //     autoHeight: false,
            //   },
            // }}
            className="product_swiper"
          >
            {bannerList}
          </Swiper>
          <div className="my_container">
            <div className="home_arrow">
              <img
                src={width > 834 ? whiteCircleLeft : mobileLeftArrow}
                alt="left arrow"
                className="home_prev"
              />
              <img
                src={width > 834 ? whiteCircleRight : mobileRightArrow}
                alt="right arrow"
                className="home_next"
              />
            </div>
          </div>
        </div>
        {/* <div className="banner_img_wrapper">
          <img
            src="https://via.placeholder.com/1920x1080/144C4B/144C4B"
            alt="homepage banner"
            className="banner_image"
          />
        </div> */}
        {/* <div className="my_container">
          <div className="banner_content_wrapper">
            <h1 className="banner_heading">
              CVD Diamond <br /> Coatings Experts
            </h1>
            <div className="cta_wrapper">
              <KnowMore link={homeURL} text="Know More" />
            </div>
          </div>
        </div> */}
      </section>

      {width > 835 ? (
        <section className="home_sec2">
          {width > 835 ? <div className="background"></div> : null}
          <div className="my_container">
            <div className="coating_wrapper">
              <h3 className="coating_heading">COATING SERVICES</h3>
              <div className="coating_content_wrapper">
                <img
                  src={coatingBackground}
                  alt="coating"
                  className="coating_back_img"
                />
                <div className="row coating_details_container">
                  <div className="col-md-3 coating_col p-0"></div>
                  <div className="col-md-3 coating_col p-0"></div>
                  <div className="col-md-3 coating_col p-0">
                    <div className="coating_box">
                      <img
                        src={nextGen}
                        alt="NEXT GEN SUBSTRATES"
                        className="coating_img"
                      />
                      <h3 className="coating_title">NEXT GEN SUBSTRATES</h3>
                      <p className="coating_desc">
                        We specialize in being able to design and develop CVD
                        diamond coating recipes for newer substrates such as
                        composites, fibre meshes, foams to name a few.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 coating_col p-0">
                    <div className="coating_box">
                      <img
                        src={electrodes}
                        alt="ELECTRODES"
                        className="coating_img"
                      />
                      <h3 className="coating_title white_title">ELECTRODES</h3>
                      <p className="coating_desc white_desc">
                        Chemically inert, corrosion and erosion resistant,
                        non-conducting and conducting diamond electrodes are
                        used for wastewater treatment and other electrochemical
                        processes.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 coating_col p-0"></div>
                  <div className="col-md-3 coating_col p-0">
                    <div className="coating_box">
                      <img
                        src={coating}
                        alt="COATING SERVICES"
                        className="coating_img"
                      />
                      <h3 className="coating_title white_title">
                        COATING SERVICES ?
                      </h3>
                      <p className="coating_desc white_desc">
                        We provide high quality CVD diamond coating ranging from
                        few micron to 10s of microns thickness on a variety of
                        materials for desired functional performance.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 coating_col p-0">
                    <div className="coating_box">
                      <img src={seal} alt="SEALS" className="coating_img" />
                      <h3 className="coating_title white_title">SEALS</h3>
                      <p className="coating_desc white_desc">
                        CVD Diamond coated silicon carbide mechanical seals
                        outperform the uncoated and DLC coated ones in terms of
                        wear resistance, heat dissipation and extended life for
                        use in oil and gas applications.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 coating_col p-0">
                    <div className="coating_box">
                      <img
                        src={silicon}
                        alt="SILICON WAFERS"
                        className="coating_img"
                      />
                      <h3 className="coating_title">SILICON WAFERS</h3>
                      <p className="coating_desc">
                        DoSi and DoI are in high demand for MEMS, mechanical
                        testing, heat dissipation, and nano-scale fabrications.
                        Diamond coatings (NCD and UNCD) are versatile in
                        electronic applications.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="mbhome_sec2">
          <div className="coating_wrapper">
            <div className="coating_content">
              <h3 className="coating_heading">COATING SERVICES</h3>
              <p className="caoting_text">
                We provide high quality CVD diamond coatings ranging from few
                microns to 10s of microns thickness on a variety of materials
                for desired functional performance.
              </p>
            </div>
            <div className="my_container">
              <div className="coating_content_wrapper">
                <div className="coating_details_container">
                  <div className="coating_main_img">
                    <img
                      src={coatingMain}
                      alt="coating"
                      className="mbcoating_img"
                    />
                  </div>
                  <div className="coating_slider_wrapper">
                    <Swiper
                      spaceBetween={0}
                      loop={true}
                      autoHeight={true}
                      modules={[Navigation]}
                      navigation={{
                        prevEl: ".coating_prev",
                        nextEl: ".coating_next",
                      }}
                      className="coating_swiper"
                    >
                      {coatingList}
                    </Swiper>
                    <div className="coating_arrow">
                      <img
                        src={greenLeft}
                        alt="left arrow"
                        className="coating_prev"
                      />
                      <img
                        src={accordOpen}
                        alt="right arrow"
                        className="coating_next"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="home_sec3">
        {width > 835 ? <div className="background"></div> : null}
        <div className="my_container">
          <div className="row about_row mx-auto">
            <div className="col-lg-6 about_col p-0">
              <div className="about_slider_wrapper">
                {width <= 767 ? <h2 className="heading">About Us</h2> : null}
                <Swiper
                  spaceBetween={0}
                  loop={true}
                  modules={[Navigation]}
                  navigation={{
                    prevEl: ".about_prev",
                    nextEl: ".about_next",
                  }}
                  className="about_swiper"
                >
                  {aboutSliderList}
                </Swiper>
                <div className="about_arrow">
                  <img
                    src={width > 834 ? whiteCircleLeft : greenLeft}
                    alt="left arrow"
                    className="about_prev"
                  />
                  <img
                    src={width > 834 ? whiteCircleRight : accordOpen}
                    alt="right arrow"
                    className="about_next"
                  />
                </div>
              </div>
              <div className=""></div>
            </div>
            <div className="col-lg-6 about_col p-0">
              <div className="about_content_wrapper">
                {width > 767 ? <h2 className="heading">About Us</h2> : null}
                <p className="subheading">
                  We're an innovative company specializing in specialized
                  coatings, including our flagship CVD diamond thin film
                  coatings.
                </p>
                <p className="desc">
                  Serving various industries with a range of thin films and
                  coatings, we've been dedicated to this field for 25 years. Our
                  strength lies in our expert team, offering customized,
                  patented coating technologies. Starting in 2017, we partnered
                  with IIT Madras and Professor MS Ramachandra Rao, leading to
                  the global commercialization of our research.
                </p>
                <div className="ticker_container">
                  <div className="ticker_details">
                    <Counter
                      startCount={0}
                      endCount={20}
                      duration={1.5}
                      countTitle="Years of Experience"
                      plus
                      // aboveThousand
                    />
                    <img
                      src={counterYear}
                      alt="year of experience"
                      className="ticker_img"
                    />
                  </div>
                  <div className="ticker_details">
                    <Counter
                      startCount={0}
                      endCount={15}
                      duration={1.5}
                      countTitle="Clients"
                      plus
                      // aboveThousand
                    />
                    <img
                      src={counterClient}
                      alt="clients"
                      className="ticker_img"
                    />
                  </div>
                </div>
                <div className="cta_wrapper">
                  <KnowMore link={homeURL} text="Know More" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="home_sec4"
        // style={{
        //   background: "#141414",
        //   backgroundImage: `url(${ourRecognitions})`,
        //   backgroundSize: width > 767 ? "cover" : "contain",
        //   overflow: "hidden",
        //   backgroundPosition: "center",
        //   backgroundAttachment: "fixed",
        //   backgroundRepeat: "no-repeat",
        // }}
      >
        {/* <div className="parallax"> */}
        {/* <img
          src={ourRecognitions}
          alt="our Recognitions"
          className="recognitions_img"
        /> */}
        <div className="my_container">
          <h3 className="heading">Our Recognitions</h3>
          <p className="desc">
            Explore our comprehensive list of certifications and recognitions
            awarded by various government bodies, showcasing our commitment to
            excellence and adherence to high standards.
          </p>
          <div className="recognitions_img_wrapper">
            <img src={Drdo} alt="Drdo" className="recog_img" />
            <img src={msme} alt="msme" className="recog_img" />
            <img src={science} alt="science" className="recog_img" />
            <img src={biotech} alt="biotech" className="recog_img" />
          </div>
        </div>
        {/* </div> */}
      </section>

      <section className="home_sec5">
        {width > 835 ? <div className="background"></div> : null}
        <div className="my_container">
          <h2 className="heading">Our Products</h2>
          <div className="row product_row">
            {width > 834 ? (
              <div className="col-lg-5 product_col order-lg-2">
                <div className="product_img_wrapper">
                  <img
                    src={ourProductData[isClicked]["img"]}
                    alt={ourProductData[isClicked]["alt"]}
                    // className="product_img"
                    // className={`product_img ${isClicked === 0 ? "product_img1" : ""}`}
                    className={`product_img ${
                      ourProductData[isClicked]["imgClass"]
                        ? ourProductData[isClicked].imgClass
                        : ""
                    }`}
                    loading="lazy"
                  />
                  {/* {ourProductData.map((item, i) => (
                  <div key={i}>
                   <img src={[isClicked][item.img]} alt={item.alt} />
                   </div>
                 ))} */}
                </div>
              </div>
            ) : null}
            <div className="col-lg-7 product_col order-lg-1">
              <Accordion defaultActiveKey="0">{productList}</Accordion>
            </div>
          </div>
        </div>
      </section>

      <EnquireNow />

      <FooterV2 />
    </>
  );
};

export default Home;
