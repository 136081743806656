import React, { useEffect } from "react";
import "./notfoundv2.scss";
import { homeURL } from "../../helpers/paths";
import KnowMore from "../../components/KnowMore/KnowMore";
import { mbNotFound, notFound } from "../../source";
import { useWindowSize } from "react-use";
import HeaderV2 from "../../components/HeaderV2/HeaderV2";

const NotFoundV2 = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <>
    <HeaderV2 />
      <section className="notfound_sec1">
        <div className="row notfound_row">
          <div className="col-lg-7 notfound_col">
            <div className="notfound_img_wrapper">
              {width > 834 ? (
                <img
                  src={notFound}
                  alt="not found"
                  className="notfound_img"
                  loading="lazy"
                  width={1010}
                  height={960}
                />
              ) : (
                <img
                  src={mbNotFound}
                  alt="not found"
                  className="notfound_img"
                  loading="lazy"
                  width={375}
                  height={355}
                />
              )}
            </div>
          </div>
          <div className="col-lg-5 notfound_col">
            <div className="notfound_content_wrapper">
              <h2 className="notfound_heading">404</h2>
              <p className="notfound_desc">
                THE PAGE YOU WERE LOOKING FOR DOES NOT EXIST
              </p>
              <div className="notfound_cta_wrapper">
                <KnowMore link={homeURL} text="Go To Homepage" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFoundV2;
