import React from "react";
import "./footerv2.scss";
import { homeURL } from "../../helpers/paths";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
import { scrollUpArrow } from "../../source";

const FooterV2 = () => {
  const { width } = useWindowSize();
  const year = new Date();

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer className="footer_sec1">
        <div className="my_container">
          <img
            src={scrollUpArrow}
            alt="up arrow"
            className="scrollup_arrow"
            onClick={scrollHandler}
          />
          <div className="footer_wrapper">
            <div className="row footer_row">
              <div className="col-md-3 footer_col">
                <div className="links_wrapper">
                  <ul className="link_list">
                    <li>
                      <Link className="link" to={homeURL}>
                        About Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 footer_col">
                <div className="links_wrapper">
                  <ul className="link_list">
                    <li>
                      <Link className="link" to={homeURL}>
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to={homeURL}>
                        Terms & Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 footer_col">
                <div className="links_wrapper">
                  <ul className="link_list">
                    <li>
                      <Link className="link" to={homeURL}>
                        Downloads
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to={homeURL}>
                        FAQ's
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 footer_col">
                <div className="links_wrapper">
                  <ul className="link_list">
                    <li>
                      <p>
                        Contact Us: <br />{" "}
                        <a href="tel:+918777366404" className="contact_link">
                          +91 8777 366 404
                        </a>
                      </p>
                      <p>
                        <a href="mailto:info@kapindra.com">info@kapindra.com</a>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_copyright_wrapper">
            <p className="copyright">
              Kapindra, All Rights Reserved - {year.getFullYear()}{" "}
              {width > 767 ? "|" : ""}{" "}
              <a href="https://www.togglehead.in/" target="_blank" rel="noreferrer" className="copyright_link">
                Crafted by Togglehead
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterV2;
