import React from "react";
import "./knowmore.scss";
import { rightWhiteArrow } from "../../source";
import { Link } from "react-router-dom";

const KnowMore = ({link, text}) => {
  return (
    <div className="knowmore_sec1">
      <Link to={link} className="knowmore_text">
        {text}
        <img
          src={rightWhiteArrow}
          alt="right arrow"
          className="knowmore_arrow"
        />
      </Link>
    </div>
  );
};

export default KnowMore;
