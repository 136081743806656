import React from "react";
// import { Link } from "react-router-dom";
import { homeURL } from "../../helpers/paths";
import { logo } from "../../source";
import "./newheader.scss";

const Header = () => {
  return (
    <>
      <header className="header">
        <div className="my_container">
          <div className="new_logo_wrapper">
            <a className="logo_link" href={homeURL}>
              <img src={logo} alt="kapindra logo" className="new_kapindra_logo" />
            </a>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
