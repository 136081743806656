import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import "./counter.scss";

const Counter = ({
  startCount,
  endCount,
  countTitle,
  plus,
  aboveThousand,
  duration,
}) => {
  return (
    <CountUp start={startCount} end={endCount} duration={duration}>
      {({ countUpRef, start }) => (
        <VisibilitySensor onChange={start}>
          <>
            <div className="counter_wrapper">
              <div className="actual_count">
                <span ref={countUpRef} />
                <span>{aboveThousand ? "K" : ""}</span>
                <span>{plus ? "+" : ""}</span>
              </div>
              <div className="count_title">{countTitle}</div>
            </div>
          </>
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

export default Counter;
