import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { commingsoonURL, homeURL, notFoundURL, thankYouURL } from "./helpers/paths";
import ComingSoonNew from "./pages/ComingSoonNew/ComingSoonNew";
import Home from "./pages/Home/Home";
import Thankyou from "./pages/ThankYou/ThankYou";
import NotFoundV2 from "./pages/NotFoundV2/NotFoundV2";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route exact path={homeURL} element={<Home />} />
        <Route exact path={commingsoonURL} element={<ComingSoonNew />} />
        <Route exact path={thankYouURL} element={<Thankyou />} />
        <Route exact path={notFoundURL} element={<NotFoundV2 />} />
        <Route path="*" element={<Navigate to={notFoundURL} />} />
      </Routes>
    </>
  );
};

export default Routing;
