import { images } from "../source";

export const bannerSliderData = [
  {
    id: 1,
    img: images.banner1.image,
    mbimg: images.mbBanner1.image,
    alt: images.banner1.alt,
    heading: "Cutting Edge <br /> Diamond Electrodes",
    link: "/",
  },
  {
    id: 2,
    img: images.banner2.image,
    mbimg: images.mbBanner2.image,
    alt: images.banner2.alt,
    heading: " Nano-Engineering <br /> Surfaces",
    link: "/",
  },
  {
    id: 3,
    img: images.banner3.image,
    mbimg: images.mbBanner3.image,
    alt: images.banner3.alt,
    heading: "CVD Diamond <br /> Coatings",
    link: "/",
  },
];

export const aboutSliderData = [
  {
    id: 1,
    img: images.aboutslider1.image,
    alt: images.aboutslider1.alt,
  },
  {
    id: 2,
    img: images.aboutslider1.image,
    alt: images.aboutslider1.alt,
  },
  // {
  //   id: 2,
  //   img: images.aboutslider2.image,
  //   alt: images.aboutslider2.alt,
  // },
];

export const ourProductData = [
  {
    id: 1,
    title: "DIAMIST",
    details: `Diamist is an innovative water-based disinfection device that uses Diamond powered technology to
    convert water into a potent disinfectant without the use of any chemical cleansing or process enhancing 
    agents.`,
    numb: "01",
    img: images.diamist.image,
    alt: images.diamist.alt,
    linkType: "external",
    link: "/",
    imgClass: "product_img_1"
  },
  {
    id: 2,
    title: "ELECTROLYTIC OZONE GENERATORS",
    details: `The electrolytic water driven ozone generators from Kapindra is the new generation electrolytic
    ozone generators which uses diamond electrode and solid polymer electrolyte membrane for ozone production. 
    `,
    numb: "02",
    img: images.electrolytic.image,
    alt: images.electrolytic.alt,
    linkType: "internal",
    link: "/",
  },
  {
    id: 3,
    title: "DIAMOND ELECTRODES FOR WASTEWATER TREATMENT",
    details: `Kapindra's boron-doped diamond electrodes revolutionize wastewater treatment by efficiently 
    generating OH* radicals without extra chemicals, ensuring high-quality, corrosion-resistant coatings with 
    a strong quality assurance policy.`,
    numb: "03",
    img: images.diamnond.image,
    alt: images.diamnond.alt,
    linkType: "internal",
    link: "/",
  },
  {
    id: 4,
    title: "DIAMOND COATED SILICON WAFERS",
    details: `Diamond coatings (NCD and UNCD) can be integrated in to a plethora of electronic applications.`,
    numb: "04",
    img: images.diamondSilicon.image,
    alt: images.diamondSilicon.alt,
    linkType: "internal",
    link: "/",
  },
];

export const mbCoatingData = [
  {
    id: 1,
    img: images.mbNextGen.image,
    alt: images.mbNextGen.alt,
    title: "NEXT GEN SUBSTRATES",
    desc: `We specialize in being able to design and develop CVD diamond coating
    recipes for newer substrates such as composites, fibre meshes, foams
    to name a few.`,
    backgroundColor: "#ffffff",
    textType: "black",
  },
  {
    id: 2,
    img: images.mbElectrodes.image,
    alt: images.mbElectrodes.alt,
    title: "ELECTRODES",
    desc: `Chemically inert, corrosion and erosion resistant, non-conducting and conducting diamond electrodes are used for wastewater treatment and other electrochemical processes.`,
    backgroundColor: "#144C4B",
    textType: "white",
  },
  {
    id: 3,
    img: images.mbCoating.image,
    alt: images.mbCoating.alt,
    title: "COATING SERVICES ?",
    desc: `We provide high quality CVD diamond coating ranging from few micron to 10s of microns thickness on a variety of materials for desired functional performance.`,
    backgroundColor: "#30A389",
    textType: "white",
  },
  {
    id: 4,
    img: images.mbSeal.image,
    alt: images.mbSeal.alt,
    title: "SEALS",
    desc: `CVD Diamond coated silicon carbide mechanical seals outperform the uncoated and DLC coated ones in terms of wear resistance, heat dissipation and extended life for use in oil and gas applications.`,
    backgroundColor: "#1C1C1C",
    textType: "white",
  },
  {
    id: 5,
    img: images.mbSilicon.image,
    alt: images.mbSilicon.alt,
    title: "SILICON WAFERS",
    desc: `DoSi and DoI are in high demand for MEMS, mechanical testing, heat dissipation, and nano-scale fabrications. Diamond coatings (NCD and UNCD) are versatile in electronic applications.`,
    backgroundColor: "#F3F3F3",
    textType: "black",
  },
];
