// General
export { default as logo } from "./images/general/kapindra.png";
export { default as whiteLogo } from "./images/general/white_logo.svg";
export { default as underconstruction } from "./images/general/under_construction.png";
export { default as call } from "./images/general/call_icon.png";
export { default as mail } from "./images/general/mail_icon.png";
export { default as location } from "./images/general/location_icon.png";
export { default as line } from "./images/general/Line_1.png";
export { default as comingsoon } from "./images/general/Coming_soon.png";
export { default as comingsoonew } from "./images/general/Coming-soon.jpg";
export { default as comingsoonewmob } from "./images/general/Mobile-coming-soon.jpg";
export { default as whiteDownArrow } from "./images/general/white_down_arrow.svg";
export { default as blackDownArrow } from "./images/general/black_down_arrow.svg";

// Homepage
export { default as coatingBackground } from "./images/home/coating_services_1.png";
export { default as rightWhiteArrow } from "./images/home/white_rightarrow.png";
export { default as nextGen } from "./images/home/next_gen.png";
export { default as electrodes } from "./images/home/electrodes.png";
export { default as coating } from "./images/home/coating.png";
export { default as seal } from "./images/home/seal.png";
export { default as silicon } from "./images/home/silicon.png";
export { default as counterYear } from "./images/home/about_us_year.png";
export { default as counterClient } from "./images/home/about_us_client.png";
export { default as whiteCircleLeft } from "./images/home/white_circle_leftarrow.png";
export { default as whiteCircleRight } from "./images/home/white_circle_rightarrow.png";
export { default as ourRecognitions } from "./images/home/our_recognitions_bg_image.jpg";
export { default as Drdo } from "./images/home/drdo.png";
export { default as msme } from "./images/home/msme.png";
export { default as science } from "./images/home/science&technology.png";
export { default as biotech } from "./images/home/biotechnology.png";
export { default as greenLeft } from "./images/home/green_leftarrow.png";
export { default as accordOpen } from "./images/home/green_rightarrow.png";
export { default as accordClose } from "./images/home/green_circle_rightarrow.png";
export { default as ourProductImg } from "./images/home/diamist.png";
export { default as coatingMain } from "./images/home/mb_coating_main.png";
export { default as whiteRightArrow } from "./images/home/black_right_arrow.svg";
export { default as greenRightArrow } from "./images/home/green_white_arrow.svg";
export { default as mobileRightArrow } from "./images/home/mobile_right_arrow.svg";
export { default as mobileLeftArrow } from "./images/home/mobile_left_arrow.svg";

// footer
export { default as scrollUpArrow } from "./images/footer/footer_up_arrow.png";

// thank you
export { default as thankYou } from "./images/thankyou/thank_you.png";
export { default as mbThankYou } from "./images/thankyou/mobile_thank_you.png";

// 404
export { default as notFound } from "./images/notfound/not_found.png";
export { default as mbNotFound } from "./images/notfound/mb_not_found.png";


export let images = {
  banner1: {
    image: require("./images/home/homepag_banner_1.jpg"),
    alt: "banner",
  },
  banner2: {
    image: require("./images/home/homepag_banner_2.jpg"),
    alt: "banner",
  },
  banner3: {
    image: require("./images/home/homepag_banner_3.jpg"),
    alt: "banner",
  },
  mbBanner1: {
    image: require("./images/home/mb_BANNER_1.jpg"),
    alt: "banner",
  },
  mbBanner2: {
    image: require("./images/home/mb_BANNER_2.jpg"),
    alt: "banner",
  },
  mbBanner3: {
    image: require("./images/home/mb_BANNER_3.jpg"),
    alt: "banner",
  },
  aboutslider1: {
    image: require("./images/home/about_us_img.jpg"),
    alt: "about us",
  },
  aboutslider2: {
    image: require("./images/home/about_us_1.png"),
    alt: "about us",
  },
  diamist: {
    image: require("./images/home/diamist.png"),
    alt: "diamist",
  },
  electrolytic: {
    image: require("./images/home/anode_Cathode_Part_Render.png"),
    alt: "ELECTROLYTIC OZONE GENERATORS",
  },
  diamnond: {
    image: require("./images/home/BDD_Electrode_Render.png"),
    alt: "DIAMOND ELECTRODES FOR WASTEWATER TREATMENT",
  },
  diamondSilicon: {
    image: require("./images/home/Silicon_Wafer_shutterstock.png"),
    alt: "DIAMOND COATED SILICON WAFERS",
  },
  mbNextGen: {
    image: require("./images/home/next_gen.png"),
    alt: "Next Gen",
  },
  mbElectrodes: {
    image: require("./images/home/electrodes.png"),
    alt: "electrodes",
  },
  mbCoating: {
    image: require("./images/home/coating.png"),
    alt: "coating",
  },
  mbSeal: {
    image: require("./images/home/seal.png"),
    alt: "seal",
  },
  mbSilicon: {
    image: require("./images/home/silicon.png"),
    alt: "silicon",
  },
};
