import React, { useEffect } from "react";
import "./thankyou.scss";
import { mbThankYou, thankYou } from "../../source";
import KnowMore from "../../components/KnowMore/KnowMore";
import { homeURL } from "../../helpers/paths";
import HeaderV2 from "../../components/HeaderV2/HeaderV2";
import { useWindowSize } from "react-use";

const Thankyou = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);


  return (
    <>
      <HeaderV2 />
      <section className="thankyou_sec1">
        <div className="row thankyou_row">
          <div className="col-lg-7 thankyou_col">
            <div className="thankyou_img_wrapper">
              <div className="thankyou_overlay"></div>
              {width > 834 ? (
                <img
                  src={thankYou}
                  alt="thank you"
                  className="thanyou_img"
                  loading="lazy"
                  width={1010}
                  height={960}
                />
              ) : (
                <img
                  src={mbThankYou}
                  alt="thank you"
                  className="thanyou_img"
                  loading="lazy"
                  width={375}
                  height={355}
                />
              )}
            </div>
          </div>
          <div className="col-lg-5 thankyou_col">
            <div className="thankyou_content_wrapper">
              <h2 className="thankyou_heading">THANK YOU</h2>
              <p className="thakyou_desc">
                YOUR MESSAGE HAS BEEN RECEIVED, WE WILL UPDATE YOU SHORTLY.
              </p>
              <div className="thankyou_cta_wrapper">
                <KnowMore link={homeURL} text="Go To Homepage" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Thankyou;
