import React from "react";
import { call, mail, location, line } from "../../source";
import "./footer.scss";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="box_1">
          <div className="call">
            <div className="call_icon">
              <img src={call} alt="call" className="call_img"></img>
            </div>
            <div className="text">
              <span className="number">
                <a href="tel:+918777366404">+ 91 8777 366 404</a>
              </span>
            </div>
          </div>

          <div className="line">
            <img src={line} alt="line" className="line_img" />
          </div>

          <div className="mail">
            <div className="mail_icon">
              <img src={mail} alt="mail" className="mail_img"></img>
            </div>
            <div className="text">
              <span className="mail_id">
                <a href="mailto:info@kapindra.com">info@kapindra.com</a>
              </span>
            </div>
          </div>
        </div>
        <div className="location">
          <div className="loaction_icon">
            <a
              href="https://maps.app.goo.gl/mTG7Ynp7TADabHPE6?g_st=iwb"
              target="_blank"
            >
              <img src={location} alt="location" className="location_img" />
            </a>
          </div>
          <div className="text">
            <a
              href="https://maps.app.goo.gl/mTG7Ynp7TADabHPE6?g_st=iwb"
              target="_blank"
            >
              <p className="location_text">
                Works - Materials Science Research Centre (MSRC), Department of
                Physics, Indian Institute of Technology, Madras, Chennai 600
                036, India.
              </p>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
