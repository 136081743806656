import React from "react";
import "./comingsoon.scss";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import { comingsoonew, comingsoonewmob } from "../../source";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { homeURL } from "../../helpers/paths";

const ComingSoonNew = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      <MetaDecorator
        metaTitle="Welcome to Kapindra"
        metaDesc="Welcome to Kapindra"
        canonicalLink={window.location.href}
      />
      <Header />
      <section className="coming_sec1">
        <div className="my_container">
          <div className="img_wrapper">
            <img
              src={width > 600 ? comingsoonew : comingsoonewmob}
              alt="coming soon"
              className="coming_desktop"
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ComingSoonNew;
