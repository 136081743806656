import React, { useState, useEffect } from "react";
import "./headerv2.scss";
import { useWindowSize } from "react-use";
import { homeURL } from "../../helpers/paths";
import { blackDownArrow, logo, whiteDownArrow, whiteLogo } from "../../source";
import { Link, useLocation } from "react-router-dom";
import HeaderNavItem from "./HeaderNavItem";
const body = document.querySelector("body");

const navLinksArr = [
  {
    id: 1,
    type: "img",
    img: whiteLogo,
    bgImg: logo,
    mainLink: "/",
    class: "logo_img",
  },
  {
    id: 2,
    type: "text",
    mainTitle: "About Us",
    mainLink: homeURL,
  },
  {
    id: 3,
    type: "text",
    mainTitle: "Products",
    mainLink: homeURL,
    typeDropdown: "dropdown",
    dropDownList: [
      {
        id: 1,
        navTitle: "Diamist",
        navLink: homeURL,
      },
      {
        id: 2,
        navTitle: "Electrolytic Ozone Generators",
        navLink: homeURL,
      },
      {
        id: 3,
        navTitle: "Diamond Electrodes For Watewater Treatment",
        navLink: homeURL,
      },
      {
        id: 4,
        navTitle: "Diamond Coated Silicon Wafers",
        navLink: homeURL,
      },
    ],
  },
  {
    id: 4,
    type: "text",
    mainTitle: "Contact Us",
    mainLink: homeURL,
  },
];

const mobNavLinksArr = [
  {
    id: 1,
    type: "text",
    mainTitle: "About Us",
    mainLink: homeURL,
  },
  {
    id: 2,
    type: "text",
    mainTitle: "Products",
    mainLink: homeURL,
    typeDropdown: "dropdown",
    dropDownLevel: 1,
    dropDownList: [
      {
        id: 1,
        navTitle: "Diamist",
        navLink: homeURL,
      },
      {
        id: 2,
        navTitle: "Electrolytic Ozone Generators",
        navLink: homeURL,
      },
      {
        id: 3,
        navTitle: "Diamond Electrodes For Watewater Treatment",
        navLink: homeURL,
      },
      {
        id: 4,
        navTitle: "Diamond Coated Silicon Wafers",
        navLink: homeURL,
      },
    ],
  },
  {
    id: 3,
    type: "text",
    mainTitle: "Contact Us",
    mainLink: homeURL,
  },
];

const HeaderV2 = () => {
  const { width } = useWindowSize();
  const [navlinkIndex, setNavlinkIndex] = useState(null);
  const [isBg, setIsbg] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [navActiveIndex, setNavActiveIndex] = useState(null);
  const { pathname } = useLocation();

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  });

  const handleScroll = () => {
    if (window.location.pathname === "/")
      if (window.pageYOffset > 60) {
        setIsbg(true);
      } else {
        setIsbg(false);
      }
  };

  const handleNavIndex = (i) => {
    if (navActiveIndex === i) {
      setNavActiveIndex(null);
    } else {
      setNavActiveIndex(i);
    }
  };

  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
    if (!isMenuActive) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
  };

  const navlinkList = navLinksArr.map((nav, i) => (
    <li key={i}>
      {nav.type === "text" ? (
        <div className="tab_wrapper">
          <Link
            to={nav.mainLink}
            className={`nav_tab ${
              nav.typeDropdown
                ? "nav_tab_dropdown"
                : navlinkIndex === i
                ? "active"
                : ""
            }`}
            // className={`nav_tab ${navlinkIndex === i ? "active" : ""}`}
            onClick={() => setNavlinkIndex(i)}
          >
            {nav.mainTitle}
            {nav.typeDropdown ? (
              <img
                src={isBg ? blackDownArrow : whiteDownArrow}
                alt="Down Arrow"
                className="down_arrow"
              />
            ) : (
              ""
            )}
          </Link>
          {nav.typeDropdown ? (
            <ul className="dropdown_box">
              {nav.dropDownList.map((drop, i) => (
                <li key={i}>
                  <Link to={drop.navLink} className="dropdown_items">
                    {drop.navTitle}
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div
          className={`logo_wrapper ${navlinkIndex === i ? "" : ""}`}
          onClick={() => setNavlinkIndex(i)}
        >
          <Link to={homeURL}>
            <img
              className={nav.class}
              src={isBg ? nav.bgImg : nav.img}
              alt="Kapindra Logo"
              onClick={scrollHandler}
            />
          </Link>
        </div>
      )}
    </li>
  ));

  useEffect(() => {
    // body.style.overflow = "auto";
    if (window.location.pathname === "/") {
      setIsbg(false);
    } else {
      setIsbg(true);
    }
  }, [pathname]);

  return (
    <>
      <section className={`header_sec1 ${isBg ? "navbar_bg" : ""}`}>
        {width > 767 ? (
          <div className="my_container">
            <div className="navlinks">
              <ul className="nav_list">{navlinkList}</ul>
            </div>
          </div>
        ) : (
          <header className="header">
            <div className="my_container">
              <div className="logo_wrapper">
                <Link className="logo_link" to={homeURL}>
                  <img
                    src={isBg ? logo : whiteLogo}
                    alt="kapindra logo"
                    className="kapindra_logo img-fluid"
                  />
                </Link>
              </div>
            </div>
            <nav className="nav_links_wrapper">
              <div
                className={`nav_line2_wrapper_flex_wrapper ${
                  isMenuActive ? "active" : ""
                }`}
              >
                <div className="logo_wrapper">
                  <Link className="logo_link" to={homeURL}>
                    <img
                      src={`${isMenuActive ? logo : whiteLogo}`}
                      alt="kapindra logo"
                      className="kapindra_logo_1 img-fluid"
                    />
                  </Link>
                </div>
                <ul className="nav_line2_wrapper_flex">
                  {mobNavLinksArr.map((navData, i) => (
                    <HeaderNavItem
                      navData={navData}
                      key={parseInt(navData.id)}
                      arrIndex={i}
                      handleNavIndex={handleNavIndex}
                      navActiveIndex={navActiveIndex}
                      setNavActiveIndex={setNavActiveIndex}
                      subMenu={navData.dropDownList}
                      typeDropdown={navData.typeDropdown}
                    />
                  ))}
                </ul>
              </div>
            </nav>

            {/* mobile UI start */}
            <div
              className={`hamburger_lines ${
                isMenuActive ? "active" : "" || isBg ? "black" : ""
              }`}
              onClick={handleHamburgerClick}
            >
              <div className="line line1"></div>
              <div className="line line2"></div>
              <div className="line line3"></div>
            </div>
          </header>
        )}
      </section>
    </>
  );
};

export default HeaderV2;
